import PageComponent from "../components/Page/Page";
import { request } from "@utilities/graphql/request";
import { parse } from "flatted";
import { promises as fs } from "fs";
import { join } from "path";
import {
  DEFAULT_CONTENTFUL_INCLUDE,
  getPageProps,
} from "../utils/cms/ContentfulService";
import { GetSettings } from "./api/getSettings";
import { useRouter } from "next/router";

export default function Page(props) {
  const { globalContent, pageContent, settings, programmeData, preview } =
    props;
  const router = useRouter();

  return (
    <PageComponent
      props={{
        globalContent,
        pageContent,
        settings,
        programmeData,
        preview,
        canonicalUrl: router.asPath,
      }}
    />
  );
}

export async function getStaticProps({ params, preview }) {
  try {
    let props: any;
    const globalsFile = await fs.readFile(
      join(process.cwd(), "pages/globals.json"),
      {
        encoding: "utf8",
      }
    );
    const globals = parse(globalsFile);

    const {
      programmeSettingsCollection,
      siteSettingsCollection,
      publicationSettingsCollection,
      courseTypeSettingCollection,
      errorMessageListCollection,
    } = await request(GetSettings, { preview });
    const programmeSettings = programmeSettingsCollection.items[0];
    const siteSettings = siteSettingsCollection.items[0];
    const publicationSettings = publicationSettingsCollection.items[0];
    const courseTypeSettings =
      courseTypeSettingCollection.items[0].linkedFrom
        .courseTypeSettingsCollection.items[0].coursesCollection.items;

    const globalErrorMessages = errorMessageListCollection;

    const settings = {
      programmeSettings,
      siteSettings,
      globalErrorMessages,
      publicationSettings,
      courseTypeSettings: {
        courses: courseTypeSettings,
      },
    };

    // get 404 content
    props = await getPageProps({
      slug: "404",
      contentType: ["eeLandingPage"],
      globals: [],
      include: DEFAULT_CONTENTFUL_INCLUDE,
      allTags: globals?.tags || null,
      preview,
    });
    props.globalContent = {
      ...props.globalContent,
      ...globals,
    };

    // https://github.com/vercel/next.js/discussions/10992#discussioncomment-103826

    return {
      // Revalidate 4 times a day
      revalidate: 60 * 60 * 6,
      props: {
        ...props,
        settings,
        ...(!!preview && { preview: true }),
      },
    };
  } catch {
    return { props: {} };
  }
}
